import Dashboard from 'pages/dashboard';
import Login from 'pages/auth/login';
import Register from 'pages/auth/register';
import { createBrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { NoteList, NoteDetailPage } from 'pages/notes';
import { CoderList, CoderDetail, CoderEditPage } from 'pages/coders';
import { DocAssistantList, DocAssistantDetail, DocAssistantEditPage } from 'pages/docAssistants';
import Builder from 'pages/builder';
import Settings from 'pages/settings';
import EmptyLayout from 'layouts/EmptyLayout';
import ClinicianInvitationPage from 'pages/clinician/ClinicianInvitationPage';
import { Clinician, ClinicianEdit, ClinicianList } from 'pages/clinician';
import { RouterPath } from './router.constant';
import { Organization, OrganizationDetail, OrganizationEdit } from 'pages/organization';
import { Inquiries } from 'pages/inquiries';
import { Attachments } from 'pages/attachments';
import { StandardQnA } from 'pages/standardQnA';
import { Staffs } from 'pages/staff';
import StaffDetail from 'pages/staff/StaffDetail';
import { SmartTexts } from 'pages/smartTexts';
import { RequiredAuth } from './RequiredAuth';

export * from './router.constant';

const RootRoutes = () => {
  return (
    <Routes>
      <Route path={RouterPath.LOGIN} element={<Login />}/>
      <Route path={RouterPath.REGISTER} element={<Register />}/>
      <Route path={RouterPath.CLINICIAN_INVITATION} element={
        <EmptyLayout>
          <ClinicianInvitationPage />
        </EmptyLayout>
      }/>
      <Route path='/' element={<RequiredAuth />}>
        <Route element={<Dashboard />}>
          <Route path={RouterPath.NOTES} element={<NoteList />} />
          <Route path={RouterPath.NOTE} element={<NoteDetailPage />} />
          <Route path={RouterPath.ORGANIZATIONS} element={<Organization />} />
          <Route path={RouterPath.ORGANIZATION} element={<OrganizationDetail />} />
          <Route path={RouterPath.ORGANIZATION_EDIT} element={<OrganizationEdit />} />
          <Route path={RouterPath.INQUIRIES} element={<Inquiries />} />
          <Route path={RouterPath.ATTACHMENTS} element={<Attachments />} />
          <Route path={RouterPath.STANDARD_QNA} element={<StandardQnA />} />
          <Route path={RouterPath.STAFFS} element={<Staffs />} />
          <Route path={RouterPath.STAFF_DETAIL} element={<StaffDetail />} />

          <Route path={RouterPath.DOC_ASSISTANTS} element={<DocAssistantList />} />
          <Route path={RouterPath.DOC_ASSISTANT} element={<DocAssistantDetail />} />
          <Route path={RouterPath.DOC_ASSISTANT_EDIT} element={<DocAssistantEditPage />} />

          <Route path={RouterPath.CODERS} element={<CoderList />} />
          <Route path={RouterPath.CODER} element={<CoderDetail />} />
          <Route path={RouterPath.CODER_EDIT} element={<CoderEditPage />} />
          
          <Route path={RouterPath.SMART_TEXTS} element={<SmartTexts />} />
          <Route path={RouterPath.BUILDER} element={<Builder />} />
          <Route path={RouterPath.CLINICIANS} element={<ClinicianList />} />
          <Route path={RouterPath.CLINICIAN} element={<Clinician />} />
          <Route path={RouterPath.CLINICIAN_EDIT} element={<ClinicianEdit />} />
          <Route path={RouterPath.SETTINGS} element={<Settings />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default RootRoutes;
