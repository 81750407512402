import { SVG } from 'assets/imagePath';
import { Drawer } from 'components/drawer';
import { AppPopover } from 'components/popover';
import { STORAGE } from 'constant/auth';
import { BREAKPOINT } from 'constant/breakpoints';
import { useViewport } from 'context/ViewportContext';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { IDrawerState } from 'types';
import NavBarAvatar from './NavBarAvatar';
import NavBarIcon from './NavBarIcon';
import NavSearch from './NavSearch';
import NavToolsMenu from './NavToolMenu';

export interface INavToolsProps {}

const NavToolsDesktop = ({}: INavToolsProps) => {
  const navigate = useNavigate();
  return (
    <>
      <NavSearch inputProps={{ placeholder: 'Search' }} />
      <NavBarIcon src={SVG.search} />
      <NavBarIcon src={SVG.add_note} />
      <NavBarIcon src={SVG.calendar} />
      <NavBarIcon src={SVG.setting} />
      <NavBarIcon src={SVG.user_icon} />
      {/* <NavCircle>1</NavCircle> */}
      {/* <NavBarDivider /> */}
      {/* <NavBarAvatar /> */}
      <AppPopover
        ButtonNode={<NavBarIcon src={SVG.more_horizontal_white} />}
        Panel={() => (
          <div className="text-black flex justify-between items-center">
            <ul>
              <li
                className="hover:bg-pearl p-2.5 cursor-pointer"
                onClick={() => {
                  localStorage.removeItem(STORAGE.APP_AUTH);
                  navigate('/login');
                }}
              >
                Sign out
              </li>
            </ul>
          </div>
        )}
        optionsPopover={{ placement: 'bottom-end' }}
        customStyle={{ left: '-40px' }}
        className="w-max"
      />
    </>
  );
};
const NavToolsMobile = ({ setDrawer }) => {
  const navigate = useNavigate();

  return (
    <>
      <NavSearch inputProps={{ placeholder: 'Search' }} />
      <NavBarIcon src={SVG.search} />
      <NavBarIcon
        src={SVG.menu}
        onClick={() =>
          setDrawer(() => ({
            Component: <NavToolsMenu />,
            open: true,
            position: 'right'
          }))
        }
      />
    </>
  );
};

export default function NavTools({ ...props }: INavToolsProps) {
  const { width } = useViewport();

  const [drawer, setDrawer] = React.useState<IDrawerState>({
    Component: <></>,
    position: 'left',
    open: false
  });

  const renderNavTools = () => {
    if (width < BREAKPOINT['2xl']) {
      return <NavToolsMobile setDrawer={setDrawer} {...props} />;
    } else {
      return <NavToolsDesktop {...props} />;
    }
  };

  return (
    <>
      {renderNavTools()}
      <Drawer
        handleClose={() => setDrawer((prev) => ({ ...prev, open: false }))}
        open={drawer.open}
        position={drawer.position}
        DrawerContent={drawer.Component}
      />
    </>
  );
}
