import { yupResolver as FormResolver } from '@hookform/resolvers/yup';
import { IMAGES, SVG } from 'assets/imagePath';
import { Auth } from 'aws-amplify';
import { Auth as AuthLayout } from 'components/auth';
import Button from 'components/button'; // Checkbox, Form
import Checkbox from 'components/checkbox';
import Form from 'components/form';
import { SET_LOADING_MODAL } from 'constant';
import { FAKE_USER, STORAGE } from 'constant/auth';
import { GlobalContext } from 'context/GlobalContext';
import { useAuth } from 'hooks/useAuth';
import { FC, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';
import { ICurrentUser, ILoginFormValues, Role } from 'types';
import { useLoginSchema } from './login.schema';

const LoginPage: FC = () => {
  const { dispatch } = useContext(GlobalContext);
  const [checked, setChecked] = useState(false);

  const form = useForm<ILoginFormValues>({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: FormResolver(useLoginSchema())
  });
  const navigate = useNavigate();

  const [errLoginFail, setErrLoginFail] = useState('');

  const onSubmit = async (values: ILoginFormValues) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true
      })
      const response = await Auth.signIn(values?.email.toLowerCase(), values?.password);
      if (!response) return;
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false
        })
      }, 100);
      navigate(RouterPath.SETTINGS);
    } catch (err) {
      console.log(err);
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false
      })
    }
  };

  return (
    <div className="flex w-full h-screen">
      <AuthLayout.Left
        image={IMAGES.side_auth_image}
        title={<AuthLayout.TitleCover />}
        subtitle="Restoring The Joy of Medicine"
      />

      <div className="flex flex-col items-center justify-center flex-1 p-5">
        <div className="flex flex-col flex-1 justify-center items-center max-w-[280px] w-full">
          <AuthLayout.Header
            icon={SVG.login_enter}
            title="Hello Again!"
            subtitle="In tristique sapien odio duis sed varius vitae. Semper imperdiet pretium massa vitae
                at ut aliquam vivamus amet."
          />
          {!!errLoginFail && (
            <p className="my-1 mt-3 text-xs text-error font-inter">{errLoginFail}</p>
          )}
          <FormProvider {...form}>
            <form className="w-full">
              <Form.EmailInput
                className="w-full mt-[30px]"
                name="email"
                inputProps={{ id: 'email', placeholder: 'E-mail' }}
              />

              <Form.PasswordInput
                className="w-full mt-5"
                name="password"
                inputProps={{ id: 'password', placeholder: 'Password' }}
              />

              <div className="flex flex-wrap justify-between items-center mt-5 gap-[5px]">
                <Checkbox.Single
                  className="ml-2"
                  label="Remember me"
                  onChange={(e) => setChecked(e.target.checked)}
                />

                <a href="#!" className="mx-2 text-sm underline text-input md:mx-0">
                  Recovery Password
                </a>
              </div>

              <Button.Primary
                className="mt-[30px] py-3"
                label="Login"
                onClick={form.handleSubmit(onSubmit)}
              />
            </form>
          </FormProvider>
        </div>
        <p className="text-sm text-input font-inter mb-7">
          Don’t have an account yet?
          <Link
            to="/register?step=1"
            className="ml-1 font-bold transition duration-200 ease-in-out text-primary hover:text-primary hover:opacity-75 focus:text-primary"
          >
            Sign Up
          </Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
