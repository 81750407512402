import avatar from './images/avatar.png';
import side_auth_image from 'assets/images/side-auth-image.png';
import get_started_img from 'assets/images/get_started_img.png';
import app_store from 'assets/images/app_store.png';

export const SVG = {
  add_note: require(`./svg/add-note.svg`).default,
  add_note_black: require(`./svg/add-note-black.svg`).default,

  angry_face: require(`./svg/angry-face.svg`).default,
  arrow_next: require(`./svg/arrow-next.svg`).default,
  arrow_prev: require(`./svg/arrow-prev.svg`).default,
  arrow_right: require(`./svg/arrow-right.svg`).default,
  arrow_down: require(`./svg/arrow-down.svg`).default,

  back_circle: require(`./svg/back-circle.svg`).default,
  back_icon: require(`./svg/back-icon.svg`).default,
  calendar: require(`./svg/calendar.svg`).default,
  calendar_black: require(`./svg/calendar-black.svg`).default,

  cancel: require(`./svg/cancel.svg`).default,
  close_fill: require(`./svg/close-fill.svg`).default,

  checked: require(`./svg/checked.svg`).default,
  chevron_down: require(`./svg/chevron-down.svg`).default,
  chevron_left: require(`./svg/chevron-left.svg`).default,

  close: require(`./svg/close.svg`).default,
  filter: require(`./svg/filter.svg`).default,
  list: require(`./svg/list.svg`).default,
  login_enter: require(`./svg/login-enter.svg`).default,
  menu: require(`./svg/menu.svg`).default,
  more_horizontal_white: require(`./svg/more-horizontal-white.svg`).default,
  more_horizontal: require(`./svg/more-horizontal.svg`).default,
  navigate: require(`./svg/navigate.svg`).default,
  phone: require(`./svg/phone.svg`).default,
  plus: require(`./svg/plus.svg`).default,
  recent_clock: require(`./svg/recent-clock.svg`).default,
  register_account: require(`./svg/register-account.svg`).default,
  register_confirm: require(`./svg/register-confirm.svg`).default,
  register_security: require(`./svg/register-security.svg`).default,
  register_smart_texts: require(`./svg/register-smart-texts.svg`).default,
  search: require(`./svg/search.svg`).default,
  search_black: require(`./svg/search-black.svg`).default,
  setting_table: require(`./svg/setting-table.svg`).default,
  setting: require(`./svg/setting.svg`).default,
  setting_black: require(`./svg/setting-black.svg`).default,

  smile_face: require(`./svg/smile-face.svg`).default,
  sound: require(`./svg/sound.svg`).default,
  sound_black: require(`./svg/sound-black.svg`).default,
  audio_range: require(`./svg/audio-range.svg`).default,
  light_bulb: require(`./svg/light-bulb.svg`).default,

  unfilter: require(`./svg/unfilter.svg`).default,
  plus_green: require(`./svg/plus-green.svg`).default,
  plus_red: require(`./svg/plus-red.svg`).default,
  pause: require(`./svg/pause.svg`).default,
  record: require(`./svg/record.svg`).default,
  skip_prev: require(`./svg/skip_prev.svg`).default,
  skip_next: require(`./svg/skip_next.svg`).default,
  next_time: require(`./svg/next_time.svg`).default,
  prev_time: require(`./svg/prev_time.svg`).default,
  mark_question_red: require(`./svg/mark-question-red.svg`).default,
  mark_question_green: require(`./svg/mark-question-green.svg`).default,
  minus: require(`./svg/minus.svg`).default,
  user_icon: require(`./svg/user_icon.svg`).default
};

export const IMAGES = {
  avatar,
  side_auth_image,
  get_started_img,
  app_store,

  side_register_image: require('assets/images/side-auth-image.png').default,
  side_login_image: require(`assets/images/side-login-image.png`).default
};
