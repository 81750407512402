import { CLINICIAN_DETAIL } from 'components/detail/data';
export const RouterPath = {
  HOME: '/',
  // Authentication
  LOGIN: '/login',
  REGISTER: '/register',
  // Dashboard
  NOTES: '/notes',
  NOTE: '/note/:noteId',
  BUILDER: '/note/edit/:builderId',
  ATTACHMENTS: '/attachments',
  ORGANIZATIONS: '/organizations',

  ORGANIZATION: '/organization/:organizationId',
  ORGANIZATION_EDIT: '/organizations/edit/:organizationId',

  INQUIRIES: '/inquiries',
  STAFFS: '/staffs',
  STAFF_DETAIL: '/staffs/:staffId',

  DOC_ASSISTANTS: '/doc-assistants',
  DOC_ASSISTANT: '/doc-assistant/:dcId',
  DOC_ASSISTANT_EDIT: '/doc-assistant/edit/:dcId',

  CODERS: '/coders',
  CODER: '/coder/:coderId',
  CODER_EDIT: '/coder/edit/:coderId',

  LEAD: '/lead',
  SYSTEM_ADMINS: '/system-admins',
  STANDARD_QNA: '/standard-qna',

  
  CLINICIANS: '/clinicians',

  CLINICIAN: '/clinician/:clinicianId',
  CLINICIAN_EDIT: '/clinician/edit/:clinicianId',

  SETTINGS: '/settings',
  SMART_TEXTS: '/smart-texts',

  //PUBLIC
  CLINICIAN_INVITATION: '/clinician-invitation',

  //NOT MATCH
  NOT_MATCH: '/*'
};
