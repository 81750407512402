import { Box } from 'components/box';
import Footer from 'components/footer/Footer';
import Form from 'components/form';
import EditableVerticalTable from 'components/gridTable/EditableVerticalTable';
import { HeaderApp } from 'components/header';
import { SET_LOADING_MODAL } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { createNewUser, createUserAPI, getOrganizationListAPI, getUserAPI, updateUserAPI } from 'services';
import { ITableColumn } from 'types';
import { capitalizeName } from 'utils/text';
import { fakeDataOrganization } from './data';
import { useApiCall } from 'hooks';

interface IClinicianEdit {}

export default function ClinicianEdit({}: IClinicianEdit) {
  const { dispatch, state } = useContext(GlobalContext);
  const { userInfo } = state;
  const { clinicianId } = useParams();
  const isNew = clinicianId?.toLowerCase() === 'new' ? true : false;
  const [fetchClinician, loading, clinicianDetail] = useApiCall(getUserAPI);
  const [fetchOrgList, ,orgList] = useApiCall(getOrganizationListAPI);
  const [createUser] = useApiCall(createUserAPI);
  const [updateUser] = useApiCall(updateUserAPI);
  const form = useForm({});
  const titleOpts = [
    { label: `${isNew ? 'Add' : 'Edit'} Clinician Details - MGR`, value: 'title-1' }
  ];
  const actionOpts = [{ label: 'Edit', value: 'edit', onClick: () => {} }];
  const [orgOptions, setOrgOptions] = useState([]);

  useEffect(() => {
    if (isNew || !userInfo) return;
    fetchClinician({ primaryAccountId: userInfo?.['custom:superId'], userRecordId: clinicianId });
  }, [clinicianId, userInfo]);

  useEffect(() => {
    if (!userInfo) return;
    fetchOrgList(userInfo?.['custom:superId']);
  }, [userInfo]);

  useEffect(() => {
    if (!orgList) return;
    let options = [];
    orgList?.forEach(item => {
      options.push({ label: item?.orgFullName, value: item?.corporateRecordId })
    })
    setOrgOptions(options);
  }, [orgList]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading
    });
  }, [loading]);

  useEffect(() => {
    if (!clinicianDetail) return;
    Object.keys(clinicianDetail).forEach((key) => {
      if (key === 'dateStarted') {
        form.setValue(key, new Date(clinicianDetail?.[key]));
        return;
      }
      form.setValue(key, clinicianDetail?.[key]);
    });
  }, [clinicianDetail]);

  const ColumnClinicianDetail: ITableColumn[] = [
    {
      field: 'firstName',
      headerName: '*First Name (Goes By)',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'lastName',
      headerName: '*Last Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'middleName',
      headerName: 'Middle Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'prefixName',
      headerName: 'Prefix Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'suffixName',
      headerName: 'Suffix Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'nickName',
      headerName: 'Nick Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    }
  ];
  const ColOrgAddress: ITableColumn[] = [
    {
      field: 'organization',
      headerName: 'Organization',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      },
      renderCell(row) {
        return (
          <Form.SelectInput
            defaultValue={!!orgOptions?.length && orgOptions?.find(item => item?.value.toString() === row?.organization.toString())}
            options={orgOptions}
            className="w-full h-full"
            name="organization"
            text
          />
        );
      }
    },
    {
      field: 'title',
      headerName: '*Title',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'degree',
      headerName: '*Degree',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'specialty',
      headerName: 'Specialty',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'department',
      headerName: '*Department',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'providerNumber',
      headerName: '*Provider Number',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    }
  ];
  const ColOther: ITableColumn[] = [
    {
      field: 'locations',
      headerName: 'Locations',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'priority',
      headerName: 'Priority',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'email',
      headerName: '*Email',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'cellPhone',
      headerName: 'Cell Phone',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'servicePhone',
      headerName: 'Service Phone',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'dateStarted',
      headerName: 'Date Started',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      },
      renderCell(row) {
        return (
          <Form.DatePicker
            name="dateStarted"
            inputProps={{ id: 'dateStarted', placeholder: '' }}
            date={form?.watch('dateStarted')}
            onSelect={(value: Date) => {
              form.setValue('dateStarted', value);
            }}
          />
        );
      }
    }
  ];

  const getFieldData = (data) => {
    let newData = {};
    Object.keys(data).forEach(key => {
      newData[key] = data[key] ? data[key] : 'empty_flag';
    });
    return newData;
  }

  const handleSubmitFunc = async (data) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true
      });
      console.log("data", data)
      if (isNew) {
        await createUser({
          ...getFieldData(data),
          dateStarted: data?.dateStarted.toISOString(),
            // middleName: data?.middleName ? data?.middleName : 'empty_flag',
            // prefixName: data?.prefixName ? data?.prefixName : 'empty_flag',
            // suffixName: data?.suffixName ? data?.suffixName : 'empty_flag',
            // providerNumber: data?.providerNumber ? data?.providerNumber : 'empty_flag',

          initials: capitalizeName(data?.firstName, data?.lastName, data?.middleName),
          userRole: 'Clinician',
          primaryAccountId: userInfo?.['custom:superId']
        });
      } else {
        console.log("data", getFieldData(data))
        await updateUser({
          body: {
            ...getFieldData(data),
            dateStarted: new Date(data?.dateStarted).toISOString(),
            initials: capitalizeName(data?.firstName, data?.lastName, data?.middleName),
            userRole: 'Clinician'
          },
          userRecordId: clinicianId,
          primaryAccountId: userInfo?.['custom:superId']
        });
      }
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false
        });
      }, 100);
      // navigate(`/organization/${organizationId}`);
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false
        });
      }, 100);
    }
  };

  return (
    <DefaultLayout>
      <HeaderApp.Edit
        title={{ options: titleOpts }}
        actions={{ options: actionOpts }}
        onSave={form.handleSubmit(handleSubmitFunc)}
      />
      <Box.Standard className="flex-1 my-2.5">
        <FormProvider {...form}>
          <form className="grid grid-cols-3 gap-[10px]">
            <EditableVerticalTable
              isEdit={true}
              columns={ColumnClinicianDetail}
              row={!isNew && form.getValues()}
            />
            <EditableVerticalTable
              isEdit={true}
              columns={ColOrgAddress}
              row={!isNew && form.getValues()}
            />
            <EditableVerticalTable
              isEdit={true}
              columns={ColOther}
              row={!isNew && form.getValues()}
            />
          </form>
        </FormProvider>
      </Box.Standard>
      <Footer />
    </DefaultLayout>
  );
}
